.header {
  display: flex;
  height: 100px;
  justify-content: space-between;
  padding: 10px 40px;
  align-items: center;
  box-shadow: 2px 2px 5px #918b8b;
  background-color: #e7e3e3;
}
.profile {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}

.btnlogout {
  padding: 10px 15px;
  float: right;
  margin: 40px;
  background-color: crimson;
  border: none;
  border-radius: 3px;
  color: white;
  font-size: larger;
  font-weight: bolder;
}
.btnlogout:hover {
  box-shadow: 2px 2px 5px black;
}
table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

* {
  box-sizing: border-box;
  margin: 0px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.25px;
}

.bg-section {
  width: 100%;
  background-image: url(./images/410206088_1490977934963812_4454948082428353287_n.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: initial !important;
  height: 250px;
}

.inside-img {
  height: 90px;
  background-color: #40061e;
  /* position: relative; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inside-img img {
  width: 100%;
  height: 100%;
}

.img-section {
  width: 208px;
  height: 208px;
  /* margin-top: -60px; */
}

.section-1 {
  background-color: #650a30 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 110px 50px 100px 50px !important;
  text-align: center;
  flex-direction: column;
}

.section-2 h1 {
  font-size: 32px;
  font-weight: 600;
  color: #ffff;
  margin-bottom: 25px;
  line-height: 28px;
  font-family: "Reuben" !important;
  letter-spacing: 2px !important;
}

.section-2 p {
  font-size: 18px;
  font-weight: 400;
  color: #ffff;
  margin-bottom: 50px;
  line-height: 28px;
}

.section-3 h5 {
  font-size: 14px;
  font-weight: 400;
  color: #ffff;
  margin-top: 50px;
  line-height: 24px;
}
.section-2 {
  width: 700px;
}

.section-3 {
  width: 60%;
}

iframe {
  border-radius: 8px !important;
  /* width: 375px !important;
  height: 535px !important; */
}

.zc-form-embed
  .page_content_wrapper
  .zc-form-fullheight
  .form-outer-wrapper
  .formContainer {
  padding: 60px !important;
}

.section-4 {
  padding: 35px 35px 27px 35px;
  background-color: #40061e;
}

.section-4 h5 {
  font-size: 14px;
  font-weight: 400;
  color: #ffff;
  text-align: center;
  /* margin-top: 50px; */
  line-height: 24px;
}

.section-5 {
  padding: 100px 30px;
  width: 60%;
  margin: auto;
}

.section-5 h1 {
  font-size: 32px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 40px;
  line-height: 28px;
  text-align: center;
  font-family: "Reuben" !important;
  letter-spacing: 2px !important;
}

.accordion-button {
  font-size: 20px;
  font-weight: 500;
  color: #0d0d0d;
  /* margin-bottom: 66px; */
  line-height: 28px;
  /* text-align: center; */
  padding: 25px 0px;
}
.accordion-item {
  border: none;
  border-bottom: 0.5px solid rgb(210, 204, 204);
}

.accordion-body {
  padding: 0px  0px   25px  0px !important;
  font-size: 16px;
  color: #707070;
  line-height: 28px;
}

.accordion-button:not(.collapsed) {
  color: #650a30 !important;
  background-color: white !important;
  box-shadow: none !important;
   border: none !important;  
}

.accordion-button:focus {
  border:none  !important;
  box-shadow: none !important;

  border-bottom: none !important;
}

.container {
  position: relative;
  width: 100%;
  overflow: hidden;

  width: 399px !important;
  height: 558px !important;
  background-color: #e6e6e6 !important;
  border-radius: 8px !important;
}

.responsive-iframe {
  position: absolute !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  border: none;
}

.zcform_Unlock_Sweet_Rewards .zc-live-primary-btn {
  width: 100% !important;
}

.formContainer {
  display: flex !important;
  justify-content: center !important;
  padding: 40px !important;
}
.zc-form-embed .page_content_wrapper {
  height: 100% !important;
}

/* @charset "UTF-8"; */
/* :root {

--white: #FFF;
--black: #000;
--gray-light: #F9F9F9;
--gray-dark: #808080;
--dark: #3F3F3F;
--pink-light: #F6EFE5;
--green-light: #19A35E;
} */

/* body {
background-color: var(--gray-light);
font-family: sans-serif;
font-size: 100%;
} */

/* .container {
max-width: 1440px;
} */

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.text-center {
  text-align: center;
}

.h2 {
  font-size: 2rem;
}

.accordions {
  /* max-width: 80%; */
  margin: auto;
}

.accordion .accordion__title {
  display: block;
  font-size: 20px;
  /* text-transform: uppercase; */
  font-weight: 500;
  background-color: transparent !important;
  border-bottom: 1px solid #0002;
  padding: 20px 0px;
  color: #0d0d0d;
  margin-top: 10px;
  transition: all 0.25s ease;
  cursor: pointer;
}

.accordion .accordion__title input {
  appearance: none;
}

.accordion .accordion__title:after {
  transition: all 0.25s ease;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "";
  float: right;
  margin-right: 10px;
}

.accordion .accordion__title:has(input:checked):after {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  content: "";
  float: right;
  margin-right: 10px;
}
.accordion__text {
  background-color: transparent !important;
}

.accordion .accordion__text {
  overflow: hidden;
  max-height: 0;
  background-color: transparent !important;
  filter: contrast(0.9);
  transition: all 0.5s ease-in-out;
  font-weight: 400;
  color: #707070;
  font-size: 16px;
  line-height: 28px;
  /* padding: 20px 0px 30px 0px  !important; */
}

.accordion .accordion__text p {
  font-weight: 100;
  line-height: 1.5rem;
  padding: 10px 10px;
  color: var(--gray-dark);
}

.accordion .accordion__title:has(input:checked) {
  color: #650a30;
}

.accordion .accordion__title:has(input:checked) + .accordion__text {
  max-height: 300px;
}

.form-label {
  font-size: 16px;
  color: #707070;
  margin-bottom: 0px !important;
  line-height: 21px;
  font-weight: 500;
}

.form-section {
  width: 521px;
  background-color: white;
  padding: 60px;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.form {
  width: 100% !important;
}

.form-section input {
  font-size: 16px;
  height: 56px;
  padding: 0px 20px;
  border-radius: 8px;
  border: 1px solid #c3cad9 !important;
  color: #000;
  width: 100% !important;

  margin-top:10px !important;
}
::placeholder {
  color: #a6a6a6 !important;
}

.input-divs {
  margin-bottom: 25px;
}

.submit-button {
  padding: 13px;
  height: 48px;
  border-radius: 50px;
  font-size: 16px;
  color: #fff;
  background-color: #650a30;
  width: 100%;
  border: none;
}

.submit-button:hover {
  background-color: #fff;
  border: 1px solid #650a30 !important;
  color: #650a30;
}

.submit-button {
  margin-top: 40px;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
@media only screen and (max-width: 768px) {
  .bg-section {
    height: 150px !important;
  }
  .img-section {
    width: 140px;
    height: 140px;
    margin-top: 0px;
  }
  .inside-img {
    height: 80px;
  }
  .section-1 {
    padding: 60px 28px !important;
  }

  .submit-button {
    margin-top: 30px;
  }

  .section-2 h1 {
    font-size: 28px;
    font-weight: 500;
    color: #ffff;
    margin-bottom: 16px;
    line-height: 40px;
  }
  .section-5 {
    padding: 60px 28px;
    width: 100%;
  }
  .section-4 {
    padding: 24px 28px 16px 28px;
    background-color: #40061e;
  }
  .section-3 {
    width: 100%;
  }
  .section-5 h1 {
    font-size: 26px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 20px;
    line-height: 40px;
    text-align: center;
  }
  .accordion .accordion__text {
    font-size: 14px;
  }
  .accordion .accordion__title {
    font-size: 16px;
  }

  .section-2 p {
    font-size: 16px;
    font-weight: 400;
    color: #ffff;
    margin-bottom: 40px;
    line-height: 26px;
  }
  .section-2 {
    width: 75%;
  }
  /* iframe {
    border-radius: 8px !important;
    width: 300px !important;
    height: 525px !important;
    /* padding: 60px !important; */
  /* margin-bottom: 40px !important; */
  /* } */

  .container {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 8px !important;
    width: 300px !important;
    height: 518px !important;
    margin-bottom: 40px !important;
  }

  .accordion-button {
    font-size: 16px;
    font-weight: 500;
    color: #0d0d0d;
    /* margin-bottom: 66px; */
    line-height: 28px;
    /* text-align: center; */
    padding: 20px 0px;
  }
  .section-3 h5 {
    font-size: 14px;
    font-weight: 400;
    color: #ffff;
    margin-top: 40px;
    line-height: 24px;
  }
  .accordion-body {
    font-size: 14px;
  }

  .thank-contanier {
    /* width: 300px; */

    border-radius: 8px;
    background-color: white;
    padding: 30px;
    text-align: left;
  }

  .accordion-body {
    padding: 0px  0px   20px  0px !important;
  }

  .dob-class {
    width: 100% !important;
  }
  .anchor-button {
    height: 42px;
    padding: 9px !important;
    font-size: 15px;
  }

  /* .img-sec{
    display: flex;
    margin-bottom: 18px;
    align-items: baseline !important;
  } */
  .img-sec {
    display: flex;
    margin-bottom: 14px;
    align-items: baseline !important;
  }
}

@media only screen and (max-width: 500px) {
  .anchor-button {
    height: 42px;
    padding: 9px !important;
    font-size: 15px;
  }
  .section-2 {
    width: 100%;
  }
  .form-section {
    width: 320px;
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    text-align: left;
  }

  .form-section input {
    height: 45px !important;
  }

  .submit-button {
    height: 42px;
    padding: 0px;
    font-size: 15px;
  }
  .form-label {
    font-size: 16px;
    color: #707070;
    margin-bottom: 8px;
    line-height: 21px;
    font-weight: 500;
  }
  .form-section input {
    font-size: 16px;
    height: 56px;
    padding: 0px 12px;
    border-radius: 5px;
    border: 1px solid #c3cad9 !important;
    color: #000;
    width: 100% !important;
    margin-top: 8px !important;
  }
  .input-divs {
    margin-bottom: 22px;
  }
  .accordion-body {
    line-height: 24px;
  }
}
@media only screen and (max-width: 500px) {
  .form-section {
    width: 350px;
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    text-align: left;
  }
}
@media only screen and (max-width: 400px) {
  .form-section {
    width: 320px;
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    text-align: left;
  }
}

.accordion-button:focus {
  border-bottom: none !important;
  box-shadow: none !important;
  /* border-bottom: 0.5px solid rgb(210, 204, 204); */
}

.thank-contanier {
  width: 521px;
  /* height: 370px; */
  border-radius: 8px;
  background-color: white;
  padding: 60px;
  text-align: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.respnse-text {
  font-size: 18px;
  line-height: 26px;
  /* margin-bottom: 25px; */
  color: #000000 !important;
  font-weight: 600;
}

.respnse-text-1 {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 15px;
  color: #0080ff !important;
}

.respnse-text-2 {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 25px;
  color: #000000 !important;
}

.respnse-text-3 {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 30px;
  color: #000000 !important;
}

.anchor-button {
  font-size: 16px;
  /* line-height: 24px; */
  /* margin-bottom:30px; */
  color: #fff !important;
  background: #650a30;
  padding: 13px 13px;
  width: 100%;
  border-radius: 50px;
  text-decoration: none !important;
}
.anchor-div {
  display: flex;
  text-align: center;
  width: 100%;
  align-items: center !important;
  justify-content: center;
}

.img-sec {
  display: flex;
  margin-bottom: 25px;
  align-items: baseline !important;
}

@media only screen and (max-width: 500px) {
  .section-2 {
    width: 300px !important;
  }
  .thank-contanier {
    width: 320px !important;
    padding: 40px !important;
  }
  .dob-class {
    width: 100% !important;
  }
  .img-sec {
    display: flex;
    margin-bottom: 14px;
    align-items: baseline !important;
  }


  .css-1q04gal-MuiDateCalendar-root {
    max-height: 285PX !important;
  }
  .css-3jvy96-MuiTypography-root-MuiDatePickerToolbar-title{
    font-size: 18px !important;
  }

  .css-1psulnz-MuiTypography-root-MuiDatePickerToolbar-title{
    font-size: 1.125rem !important;
  }
  /* .css-1lugbff-MuiPickersLayout-root .MuiPickersLayout-toolbar{
    display: none !important;
  } */

  .css-1eurbeq-MuiPickersToolbar-root-MuiDatePickerToolbar-root {
padding: 8px 24px !important;

}
}

.gifimg {
  width: 100%;
}

.accordion-button::after {
  background-image: url(../src/images/Artworkdown.svg);
  /* background-image:url(../src/images/Artworkup.svg) !important; */
  background-size: 14px 9px !important;
  background-position: center;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(../src/images/Artworkup.svg);
  background-size: 14px 9px !important;
}

.imgdiv {
  width: 40px;
  /* height: 50px; */
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.dob-class {
  width: 100% !important;
}

.tbox {
  min-width: 95% !important;
  max-width: 100% !important;
  background-color: white !important;
}
.form {
  width: 100% !important;
}
.react-datepicker__input-container {
  width: 176% !important  ;
}



.css-4jnixx-MuiStack-root {
  display: block !important;
}

.MuiOutlinedInput-notchedOutline {
  border:none !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
  padding-right: 0px !important;
}

.css-4jnixx-MuiStack-root{
  padding-top: 0px !important;
  margin-bottom: 0px !important;
}

.css-16d8vrz-MuiPickersToolbar-root-MuiDatePickerToolbar-root{
  max-width: 100px !important;
  padding: 10px !important;
  /* display: none !important; */
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border: none !important;
}

.css-3jvy96-MuiTypography-root-MuiDatePickerToolbar-title {
font-size: 1.25rem !important;
}

.accordion
{
  --bs-accordion-transition:none !important;
  --bs-accordion-border-radius:none !important;
}

.MuiTextField-root{
width: 100% !important;
}

.css-1bn53lx{
padding-right:0px !important;
border:none !important;

}

.css-5oi4td {
max-height:285px !important;
}

.css-k3zp98{
font-size: 1.25rem !important;
}

.css-1d2g9rv{
font-size: 1.25rem !important;
}


.accordion-button:not(.collapsed){
  box-shadow: none !important;

}

.otp-input{
  margin-bottom: 10px !important;
}

.css-160unip{
  padding-top: 0px !important;
}
